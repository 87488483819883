import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Card.module.css'; // Importing CSS Module

interface CardProps {
  serviceName: string;
  description: string;
  route: string;
}

const Card: React.FC<CardProps> = ({ serviceName, description, route }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <span className={styles.card__title}>{serviceName}</span>
      <p className={styles.card__content}>{description}</p>
      <form className={styles.card__form}>
        <button
          type="button"
          className={styles.card__button}
          onClick={() => navigate(route)}
        >
          Let's Explore
        </button>
      </form>
    </div>
  );
};

export default Card;
