import React from 'react';
import { Typography, Box } from '@mui/material';
import styles from './WelcomePage.module.css'; // Import the CSS module styles

import Header from './Header';
import Footer from './Footer';

const WelcomePage: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bgcolor="#34567A">


      {/* Main content container */}
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" padding="2rem">

        {/* Left Section - Location Feature */}
        <Box flex="1" padding="1rem" color="#ffffff">
          <Typography variant="h4" gutterBottom>
            Discover Nearby Pet Services
          </Typography>
          <Typography variant="body1">
            Find the best veterinarians, pet sitters, dog parks, and pet-friendly cafes in your area.
            Our location feature brings essential pet services right to you.
          </Typography>
        </Box>

        {/* Right Section - Mobile Image */}
        <Box flex="1" display="flex" justifyContent="center" alignItems="center" bgcolor="white" width="50%">
          <Box width="80%" height="80%" display="flex" justifyContent="center" alignItems="center">
            <img src={require('../assets/Location-step1.jpg')} alt="Mobile Preview" style={{ height: '80%', maxHeight: '500px' }} />
          </Box>
        </Box>
      </Box>


    </Box>
  );
};

export default WelcomePage;


