import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from '../styles/Header.module.scss';

interface HeaderProps {
  buttonComp?: React.ReactNode;
  hasHiddenAuthButtons: boolean;
  showCardFunction: (isShown: boolean) => void;
}

interface NavLink {
  path: string;
  label: string;
}

const Header: React.FC<HeaderProps> = ({
  hasHiddenAuthButtons,
  showCardFunction
}) => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Navigation links configuration
  const navLinks: NavLink[] = [
    { path: "/", label: "Home" },
    { path: "/policy", label: "Policy" },
    { path: "/support", label: "Support" },
    { path: "/removeaccount", label: "Remove Account" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const nav = document.querySelector(`.${styles.mainNav}`);
      const menuTrigger = document.querySelector(`.${styles.menuTrigger}`);

      if (isMobileMenuOpen && nav && !nav.contains(event.target as Node) &&
        menuTrigger && !menuTrigger.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobileMenuOpen]);

  // Close mobile menu when window is resized above mobile breakpoint
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className={styles.preHeader}>
        <div className={styles.preHeaderContent}>
          <ul className={styles.contactInfo}>
            <li>
              <a href="mailto:contact@petcarians.com.au">
                <i className="fa fa-envelope"></i> contact@petcarians.com.au
              </a>
            </li>
            <li>
              <a href="tel:+61431982028">
                <i className="fa fa-phone"></i> +61 431 982 028
              </a>
            </li>
          </ul>
          <ul className={styles.socialMedia}>
            {['facebook', 'twitter', 'instagram'].map((platform) => (
              <li key={platform}>
                <a href="#" aria-label={`Visit our ${platform} page`}>
                  <i className={`fa fa-${platform}`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <header
        className={`${styles.header} ${isSticky ? styles.headerSticky : ""}`}
        role="banner"
      >
        <div className={styles.headerContent}>
          <div
            className={styles.logo}
            role="button"
            tabIndex={0}
            onClick={() => navigate("/")}
            onKeyPress={(e) => e.key === 'Enter' && navigate("/")}
          >
            <img src={require("../assets/paw.png")} alt="PetCarians Logo" />
          </div>

          <nav
            className={`${styles.mainNav} ${isMobileMenuOpen ? styles.active : ""}`}
            role="navigation"
          >
            {navLinks.map(({ path, label }) => (
              <button
                key={path}
                onClick={() => navigate(path)} // Use navigate to route programmatically
                className={`${styles.navLink} ${window.location.pathname === path ? styles.active : ""}`}
              >
                {label}
              </button>
            ))}
          </nav>

          <div aria-label="Download our apps" className={styles.downloadButtons}>
            <a
              href="https://apps.apple.com/au/app/petcarians/id6496864478"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download on App Store"
            >
              <img
                src={require("../assets/appstorebadge.png")}
                alt="Download on App Store"
                loading="lazy"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.petcarians"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Get it on Google Play"
            >
              <img
                src={require("../assets/googleplaybadge.png")}
                alt="Get it on Google Play"
                loading="lazy"
              />
            </a>
          </div>

          <button
            className={styles.menuTrigger}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-expanded={isMobileMenuOpen}
            aria-label="Toggle navigation menu"
          >
            <span>{isMobileMenuOpen ? "Close" : "Menu"}</span>
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
