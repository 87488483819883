import React from 'react';
import { Typography, Box } from '@mui/material';

import "./PolicyPage.css";
import Header from './Header';
import Footer from './Footer';

const PolicyPage: React.FC = () => {
  return (


    <Box display="flex" flexDirection="column" minHeight="100vh" justifyContent="space-between" bgcolor={"#34567A"}>

      <Box
        className="main-container-policy"
      >
        <Box className="content-policy">
          <Box className="content-text-policy">
            <Typography variant="h5" component="h1" gutterBottom align="left" >
              Privacy Policy and Data Collection Statement
            </Typography>
            <Typography variant="body1" component="h2" gutterBottom align="left">
              Last Updated: 1st November 2024
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              User Consent
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              By using our App, you consent to the collection and use of your personal information as outlined in this Privacy Policy. You may withdraw your consent at any time by deleting your account.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              How We Use Your Information
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              We utilize the personal information we collect solely for the following purposes:
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              <strong>App Functionality:</strong> Enhancing your App experience by connecting you with friends and family members for pet care arrangements.
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              <strong>Communication:</strong> Keeping you informed about pet care arrangements, updates to our services, and other relevant information.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Pet Sitter Responsibility
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              PetCarians assumes that the pet sitter you are booking for your pet is someone you already know and trust. Any conflicts or disputes that arise during the pet care arrangement are solely the responsibility of you and the pet sitter. PetCarians does not mediate or intervene in such matters.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Sharing and Disclosure
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              Your privacy is our priority. We do not sell, rent, or trade your personal information to any third parties.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Security of Your Information
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              We are dedicated to ensuring the security of your personal information. We employ robust security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Payment and Transaction Policy
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              It is the sitter's responsibility to collect payment from the requester once the request is confirmed. PetCarians will not be involved in any money transactions or disputes.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Your Rights
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              You have the right to access, correct, or delete your account information held by us. Should you decide to delete your account, please note that all your information will be permanently removed, and you'll need to sign up again to use the App in the future. If you wish to exercise any of these rights or have any inquiries, please reach out to us using the contact details provided below.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Pet Insurance and Advice
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              PetCarians does not provide any insurance or advice to the customers related to their pets. Pet arrangements are entirely your responsibility.
            </Typography>

            <Typography variant="h6" gutterBottom align="left">
              Contact Us
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              Should you have any questions, concerns, or complaints regarding our Privacy Policy or data practices, please don't hesitate to contact our Privacy Officer:
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              <strong>Email:</strong> admin@petcarians.com.au
              <br />
              <strong>Phone:</strong> +61 431 982 028
            </Typography>
          </Box>
        </Box>

      </Box>

    </Box>
  );
};

export default PolicyPage;