import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import styles from './WelcomePage.module.css'; // Import the CSS module styles

import Card from './Card';
import Header from './Header';
import Footer from './Footer';
import CardMobile from './CardMobile';

const WelcomePage: React.FC = () => {
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const renderContent = [
    {
      service: "Locate Trusted PetCarians Nearby",
      describe: "Easily connect with a network of skilled pet sitters and pet care professionals within your local area. Browse detailed profiles, read verified reviews, and select the most reliable PetCarian to ensure the safety and well-being of your pet.",
      route: "/serachByLocation",
    },
    {
      service: "PetCarians Within Your Network",
      describe: "Find pet sitters from within your personal or social network. Effortlessly connect with trusted PetCarians nearby, explore their profiles, and choose a sitter who shares your commitment to the care and safety of your pets.",
      route: "/serachInCircle",
    },
    {
      service: "Become Part of the PetCarians Community",
      describe: "Join PetCarians today to gain access to a supportive community of pet owners and caregivers. With our comprehensive platform, you’ll have the tools and resources needed to ensure your pets receive the highest standard of care, all while connecting with like-minded individuals.",
      route: "/",
    },
  ];

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" justifyContent="space-between" className={styles.pageContainer}>

      <Box className={styles.mainContainer}>
        <Box className={styles.content} sx={{
          backgroundImage: `url(${require('../assets/hero.png')})`, // Add your background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '600px',
        }}>
          <Box className={styles.contentText}>

            <Typography variant="h4" component="p" gutterBottom>
              Welcome to PetCarians! Our platform connects you with trusted pet care professionals in your local community, so you can rest easy knowing your pets are in safe hands.
            </Typography>
            <Typography variant="body1" component="h4" gutterBottom>
              With PetCarians, you can effortlessly find the right pet sitter who shares your love and commitment to pets. Your furry friends deserve the best, and we’re here to help you find the perfect match.
            </Typography>
            <Typography variant="body1" component="h4">
              Whether you're taking a vacation or need daily care, PetCarians makes it easy to find reliable and caring pet sitters who will treat your pets like their own.
            </Typography>
          </Box>
        </Box>

        <Box className={styles.serviceSection}>
          <Box className={styles.cardContainer}>
            {renderContent.map((item, index) => (
              <Card serviceName={item.service} description={item.describe} route={item.route} key={index} />
            ))}
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default WelcomePage;
