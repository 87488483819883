// ContactPage.tsx
import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import './ContactPage.css';
import Footer from './Footer';
import Header from './Header';


const ContactPage: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" justifyContent="space-between" bgcolor={"#34567A"}>

      <Box
        className="main-container-contact"
      >
        <Box className="content-contact">

          <Box className="content-text-contact">
            <Typography variant="h6" component="h1" gutterBottom>
              Need Assistance? We're Here to Help
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              If you require any assistance with your PetCarians account or have any questions about our services, we're always here to support you!
              Whether you have a question about your account, need help with a feature, or just want to provide feedback, don't hesitate to reach out.
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Feel free to contact us via email or phone. Our team is ready to help you resolve any issues and ensure you have a smooth experience with PetCarians.
            </Typography>
            <Box mt={4}>
              <Typography variant="h5" component="p" gutterBottom>
                Contact Information
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Email:</strong> admin@petcarians.com.au
                <br />
                <strong>Phone:</strong> +61 431 982 028
              </Typography>

            </Box>
          </Box>

        </Box>
      </Box>


    </Box>

  );
};

export default ContactPage;

