import React from 'react';
import styles from './DownloadApp.module.css';

const DownloadApp: React.FC = () => {
    return (
        <div className={styles.downloadApp}>
            <div className={styles.downloadAppContent}>
                <h2 className={styles.downloadTitle}>Download Our App</h2>
                <p className={styles.downloadDescription}>
                    Get the Petcarians app for a seamless experience managing your pet care needs.
                </p>
                <div className={styles.storeButtons}>
                    <a
                        href="https://apps.apple.com/au/app/petcarians/id6496864478"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.storeLink}
                    >
                        <img
                            src="/assets/images/apple-store-badge.png"
                            alt="Download on the App Store"
                            className={styles.storeIcon}
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=au.com.petcarians"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.storeLink}
                    >
                        <img
                            src="/assets/images/google-play-store-badge.png"
                            alt="Get it on Google Play"
                            className={styles.storeIcon}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DownloadApp;
