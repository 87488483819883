import React from "react";
import { Box } from "@mui/system";
import styles from '../styles/Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <Box className={styles.footer}>
      <p className={styles.footerText}>
        © 2024 <span>PetCarians</span> | All Rights Reserved <br />
        ABN: 74 680 425 656
      </p>



      <div className={styles.footerSocialMedia}>
        {["facebook", "twitter", "instagram"].map((platform) => (
          <a
            key={platform}
            href={`https://www.${platform}.com`}
            aria-label={`Visit us on ${platform}`}
          >
            <i className={`fa fa-${platform}`}></i>
          </a>
        ))}
      </div>
    </Box>
  );
};

export default Footer;
